<!--
 * @Descripttion: okr首页导航
 * @version: 1.0.0
 * @Author: xup@imyfone.cn
 * @Date: 2022-07-13 16:33:53
 * @LastEditors: 何琦 heqi@imyfone.cn
 * @LastEditTime: 2023-04-09 15:56:19
-->
<template>
  <el-affix :offset="71">
    <div class="okr-nav home-nav scroll-bar">
        <ul class="padding-16 nav-list">
            <li
                :class="[item.selected ? 'active' : '']"
                @click="changeView(item)"
                v-for="item in navList" :key="item.navId">

                <template v-if="item.routerName == false">
                    <h3 :class="['title', item.selected ? 'active' : '']" @click="changeView(item, 'h3')">{{item.navName}}</h3>
                    <ul>
                      <li :class="['nav-item', children.selected ? 'active' : '']" v-for="children in item.children" :key="children.navId"
                            @click.stop="changeView(children)">
                            <UserAvatar v-if="children.userName" :user="{avatar: children.headerImg, name: children.userName, id: children.userId}" size="24" fontSize="10" medalSize="36" :isPreview="false" :noPreview="false"></UserAvatar>
                            <template v-if="children.navName.length > 6 || (children.userName && children.navName.length > 3)">
                              <el-tooltip class="box-item" effect="dark" :content="children.navName" placement="top-start">
                                <p class="oneline-overflow navName">{{children.navName}}</p>
                              </el-tooltip>
                            </template>
                            <p v-else class="oneline-overflow navName">{{children.navName}}</p>
                        </li>
                    </ul>
                    <div class="line"></div>
                </template>

                <template v-else>
                    <p :class="['title', 'other', item.selected ? 'active' : '']">{{item.navName}}<Dot :count="count.audit+ count.evaluate" v-if="item.navName === '审核与评价' && count.audit+ count.evaluate > 0"></Dot></p>
                </template>

            </li>
        </ul>
    </div>
  </el-affix>
</template>

<script>
import { computed, onMounted, reactive, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getOkrEvaluateCount } from '@/apis/okr.js'
export default {
  name: 'OkrNav',
  setup () {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()

    const state = reactive({
      navList: [
        { navName: '我的OKR', navId: 1, selected: false, routerName: false, routerId: 1.1, children: [] }, // myokr
        { navName: '审核与评价', navId: 2, selected: false, routerName: 'evaluation' },
        { navName: '全部目标', navId: 3, selected: false, routerName: 'allTarget', routerId: computed(() => store.state.okr.allTargetId) },
        { navName: '对齐视图', navId: 4, selected: false, routerName: 'targetAligned' }
      ],
      departmentList: computed(() => store.state.okr.departmentList),
      count: computed(() => store.state.okr.evaluateCount),
      medalId: computed(() => store.state.userInfo.userInfos.id)
    })

    watch(() => store.state.okr.menu, (newValue) => {
      const arr = newValue.filter(i => i.name === '审核与评价')
      if (!arr.length) state.navList = state.navList.filter(i => i.navName !== '审核与评价')
    }, { immediate: true })

    const changeView = (item, target) => {
      if (item.navId === 1 && target !== 'h3') return false
      // console.log(item)
      state.navList.forEach(i => {
        i.selected = false
        i.children && i.children.forEach(i => (i.selected = false))
      })

      if (item.child) {
        state.navList[0].selected = true
      }

      if (item.children) {
        item.children[0].selected = true
      }

      item.selected = true

      if (item.navId === 1) {
        router.push({ name: item.children[0].routerName, params: { depName: item.children[0].navName, id: item.children[0].routerId } })
      } else if (item.routerId) {
        store.commit('okr/setTargetType', '公司目标')
        store.commit('okr/setAllTargetSel', process.env.VUE_APP_COMPANY_NAME)
        item.navName === '全部目标' ? router.push({ name: item.routerName, params: { allTargetID: item.routerId, companyName: process.env.VUE_APP_COMPANY_NAME } }) : router.push({ name: item.routerName, params: { depName: item.navName, id: item.routerId } })
      } else {
        item.routerName && router.push({ name: item.routerName })
      }
    }

    const getDepartment = () => {
      // const tempArr = [
      //   { navName: '麦风科技', navId: 1.1, selected: false, child: true, routerName: 'myokr', routerId: 1.1 },
      //   { navName: 'EN营销部', navId: 1.2, selected: false, child: true, routerName: 'myokr', routerId: 1.2 },
      //   { navName: 'WA营销部', navId: 1.3, selected: false, child: true, routerName: 'myokr', routerId: 1.3 },
      //   { navName: '张三', navId: 1.4, selected: false, child: true, routerName: 'myokr', routerId: 1.4, headerImg: 'https://static-legacy.dingtalk.com/media/lQLPDhstbDRWzWXNAk7NAk6wHwv6UHGxHCUCGxf85oDuAA_590_590.png' }
      // ]
      //   state.navList[0].splice(1, 0, ...tempArr)
      const tempArr = []
      state.departmentList.forEach(i => {
        tempArr.push({
          navName: i.name,
          navId: i.dept_id,
          selected: false,
          child: true,
          routerName: 'myokr',
          routerId: i.dept_id,
          headerImg: i.user_avatar ? i.user_avatar : undefined,
          userName: i.userName || '',
          userId: i.user_id
        })
      })
      state.navList[0].children = tempArr
    }

    getDepartment()

    // 获取审核评价数
    const getAuditCount = () => {
      if (state.navList.filter(i => i.navName === '审核与评价').length) {
        getOkrEvaluateCount().then(({ code, data }) => {
          if (code === 0) {
            // console.log(data)
            store.commit('okr/setEvaluateCount', data)
          }
        })
      }
    }
    onMounted(() => {
      if (route.meta.activeMenu === 'myokr') {
        // console.log(state.navList)
        state.navList[0].selected = true
        const arr = state.navList[0].children.filter(i => i.navId === Number(route.params.id))
        arr.length && (arr[0].selected = true)
      } else if (route.meta.activeMenu === 'evaluation') {
        // console.log(state.navList)
        state.navList.filter(i => i.navName === '审核与评价')[0].selected = true
      }
      getAuditCount()
    })

    return {
      ...state,
      changeView,
      router
    }
  }
}
</script>

<style lang="less" scoped>
@import url(../../../../../../assets/less/okrNav.less);
.home-nav {
    color: #333;
    padding: 20px 0;
    padding-bottom: 10px;

    a {
        color: @default-text-color;
        &:hover {
            color: @active-text-color;
        }
    }

    .line {
        margin-bottom: 8px;
    }

    .title {
        font-size: 14px;
        padding-left: 8px;
        color: #333;
        font-weight: normal;
        &.active {
          font-weight: bold;
          color: @active-text-color;
        }
        &.active a {
            color: @active-text-color;
        }
        &.other {
          margin-bottom: 8px;
          padding: 6px 8px;
        }
        &:hover {
          background: #f2f1fe;
          border-radius: 28px;
          cursor: pointer;
          color: @active-text-color;
        }
        & .dot {
          margin-left: 14px;
          transform: translateY(-1px);
        }
    }
    .nav-list {
        .nav-item {
            justify-content: flex-start;
            img {
                margin-right: 4px;
                width: 18px;
                height: 18px;
                border-radius: 50%;
            }
        }
    }
}
h3.active {
  color: @active-text-color;
}
.nav-list > li:first-child h3 {
  &:hover {
    background: #fff !important;
  }
}
.navName {
  width: 114px;
}
</style>
